<template>
  <div>
    <div class="box">
      <div class="nav-box">
        <div class="fuwuxiangmu">
          <div class="fuwuleft">
            <el-checkbox-group v-model="group" size="large">
              <el-checkbox-button
                v-for="city in cities"
                :key="city.id"
                :label="city.id"
              >
                {{ city.name }}
              </el-checkbox-button>
            </el-checkbox-group>
          </div>
          <div @click="shuaxin" class="flush">
            <img
              class="flush-img"
              src="../assets/img/ding/shuaxin.png"
              alt=""
            />
            <p class="flush-p">刷新</p>
          </div>
        </div>

        <div class="xuanze">
          <div class="xuanzeleft">
            <!-- 合作单位 -->
            <div class="xuanbox">
              <el-form ref="form">
                <el-form-item label="合作单位">
                  <el-col :span="11">
                    <el-select
                      filterable
                      v-model="heZuoVal"
                      placeholder="选择合作单位"
                    >
                      <el-option
                        v-for="item in heZuoOptions"
                        :key="item.cid"
                        :value="item.cid"
                        :label="item.name"
                      >
                        {{ item.name }}
                      </el-option>
                    </el-select>
                  </el-col>
                </el-form-item>
              </el-form>
            </div>
            <!-- 订单状态 -->
            <div class="xuanbox">
              <el-form ref="form">
                <el-form-item label="订单状态">
                  <el-col :span="11">
                    <el-select
                      filterable
                      v-model="statusVal"
                      placeholder="选择订单状态"
                    >
                      <el-option
                        v-for="item1 in statusOptions"
                        :key="item1.id"
                        :value="item1.id"
                        :label="item1.name"
                      >
                        {{ item1.name }}
                      </el-option>
                    </el-select>
                  </el-col>
                </el-form-item>
              </el-form>
            </div>
          </div>

          <div class="xuanzeright">
            <!-- 创建时间 -->
            <div class="xuanbox">
              <el-form ref="form">
                <el-form-item label="创建时间">
                  <el-col :span="11">
                    <el-date-picker
                      v-model="valueTime1"
                      value-format="YYYY-MM-DD"
                      type="daterange"
                      @change="begin_time"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                    >
                    </el-date-picker>
                  </el-col>
                </el-form-item>
              </el-form>
            </div>
            <!-- 完成时间 -->
            <div class="xuanbox">
              <el-form ref="form">
                <el-form-item label="完成时间">
                  <el-col :span="11">
                    <el-date-picker
                      v-model="valueTime2"
                      value-format="YYYY-MM-DD"
                      type="daterange"
                      range-separator="至"
                      start-placeholder="开始日期"
                      end-placeholder="结束日期"
                    >
                    </el-date-picker>
                  </el-col>
                </el-form-item>
              </el-form>
            </div>
            <!-- 救援时间 -->
            <div class="xuanbox">
              <el-form ref="form">
                <el-form-item label="救援时间">
                  <el-date-picker
                    class="yuTime"
                    v-model="value1"
                    value-format="YYYY-MM-DD HH:mm:ss"
                    type="datetime"
                    placeholder="请选择时间"
                    size="default"
                  >
                  </el-date-picker>
                  <el-date-picker
                    class="yuTime"
                    v-model="value2"
                    value-format="YYYY-MM-DD HH:mm:ss"
                    type="datetime"
                    placeholder="请选择时间"
                    size="default"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-form>
            </div>
          </div>

          <!-- 预约时间 -->
          <!-- <div class="xuanbox">
            <el-form ref="form">
              <el-form-item label="预约时间">
                <el-col :span="11">
                  <el-date-picker
                    v-model="valueTime3"
                    value-format="YYYY-MM-DD"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                  >
                  </el-date-picker>
                </el-col>
              </el-form-item>
            </el-form>
          </div> -->
        </div>

        <div class="xuanze2">
          <div class="xuandan">
            <i
              @click="typedan(0)"
              :class="[typedanclorl === 0 ? 'quanbustyle2' : 'quanbustyle']"
              >全部</i
            >
            <i
              @click="typedan(1)"
              :class="[typedanclorl === 1 ? 'jishistyle2' : 'jishistyle']"
              >即时单</i
            >
            <i
              @click="typedan(2)"
              :class="[typedanclorl === 2 ? 'yuyuestyle2' : 'yuyuestyle']"
              >预约单</i
            >
            <i
              @click="typedan(3)"
              :class="[typedanclorl === 3 ? 'shunlustyle2' : 'shunlustyle']"
              >顺路单</i
            >
          </div>

          <div class="xuandan">
            <div class="newxuan">
              <i
                @click="shoutype(2)"
                :class="[shoufei == 2 ? 'shoufeiz' : 'zhang']"
                >挂账</i
              >
              <i class="tiao"></i>
              <i
                @click="shoutype(1)"
                :class="[shoufei == 1 ? 'shoufeiz' : 'zhang']"
                >现金</i
              >
              <i class="tiao" v-if="getismain"></i>
              <i
                @click="shoutype(3)"
                :class="[shoufei == 3 ? 'shoufeiz' : 'zhang']"
                v-if="getismain"
                >平台</i
              >
            </div>
            <!-- 订单来源 -->
            <div class="xuan1" v-if="getismain">
              <el-form ref="form">
                <el-col :span="11">
                  <el-select
                    filterable
                    v-model="sourcevalue"
                    placeholder="订单来源"
                    @change="sourcechange"
                  >
                    <el-option
                      v-for="item in sourceList"
                      :key="item.id"
                      :value="item.value"
                      :label="item.label"
                    >
                    </el-option>
                  </el-select>
                </el-col>
              </el-form>
            </div>
            <!-- 公司类型 -->
            <div class="xuan1" v-if="getismain">
              <el-form ref="form">
                <el-col :span="11">
                  <el-select
                    filterable
                    v-model="companyType"
                    placeholder="公司类型"
                  >
                    <el-option
                      v-for="item in companyTypelist"
                      :key="item.id"
                      :value="item.id"
                      :label="item.name"
                    >
                    </el-option>
                  </el-select>
                </el-col>
              </el-form>
            </div>
            <!-- 全部技师 -->
            <div class="xuan1" v-if="getismain">
              <el-form ref="form">
                <el-col :span="11">
                  <el-select filterable v-model="tech" placeholder="全部技师">
                    <el-option
                      v-for="item1 in techlist"
                      :key="item1.uid"
                      :value="item1.uid"
                      :label="item1.nickname"
                    >
                      {{ item1.nickname }}
                    </el-option>
                  </el-select>
                </el-col>
              </el-form>
            </div>
            <!-- 全部客服 -->
            <div class="xuan1" v-if="getismain">
              <el-form ref="form">
                <el-col :span="11">
                  <el-select filterable v-model="kefu" placeholder="全部客服">
                    <el-option
                      v-for="item1 in kefulist"
                      :key="item1.uid"
                      :value="item1.uid"
                      :label="item1.nickname"
                    >
                      {{ item1.nickname }}
                    </el-option>
                  </el-select>
                </el-col>
              </el-form>
            </div>
            <!-- 全部调度 -->
            <div class="xuan1" v-if="getismain">
              <el-form ref="form">
                <el-col :span="11">
                  <el-select filterable v-model="diaodu" placeholder="全部调度">
                    <el-option
                      v-for="item1 in diaodulist"
                      :key="item1.uid"
                      :value="item1.uid"
                      :label="item1.nickname"
                    >
                      {{ item1.nickname }}
                    </el-option>
                  </el-select>
                </el-col>
              </el-form>
            </div>
            <!-- 全部业务员 -->
            <div class="xuan1" v-if="getismain">
              <el-form ref="form">
                <el-col :span="11">
                  <el-select
                    filterable
                    v-model="saleman"
                    placeholder="全部业务员"
                  >
                    <el-option
                      v-for="item1 in saleslist"
                      :key="item1.uid"
                      :value="item1.uid"
                      :label="item1.nickname"
                    >
                      {{ item1.nickname }}
                    </el-option>
                  </el-select>
                </el-col>
              </el-form>
            </div>
          </div>
        </div>

        <div class="danxuanbox">
          <div class="danxuanleft">
            <!-- 订单审核 -->
            <div class="danxuan" v-if="getismain">
              <el-form ref="form">
                <el-form-item label="订单审核">
                  <el-col :span="11">
                    <el-radio-group v-model="shenHeVal">
                      <el-radio-button :label="1">等待审核</el-radio-button>
                      <el-radio-button :label="2">初审未过</el-radio-button>
                      <el-radio-button :label="3">终审未过</el-radio-button>
                      <el-radio-button :label="4">审核通过</el-radio-button>
                    </el-radio-group>
                  </el-col>
                </el-form-item>
              </el-form>
            </div>

            <!-- 投诉 -->
            <div class="danxuan" v-if="getismain">
              <el-form ref="form">
                <el-form-item label="投诉">
                  <el-col :span="11">
                    <el-radio-group v-model="touSuVal">
                      <el-radio-button :label="2">是</el-radio-button>
                      <el-radio-button :label="1">否</el-radio-button>
                    </el-radio-group>
                  </el-col>
                </el-form-item>
              </el-form>
            </div>
            <!-- 回访 -->
            <div class="danxuan" v-if="getismain">
              <el-form ref="form">
                <el-form-item label="回访">
                  <el-col :span="11">
                    <el-radio-group v-model="huiFangVal">
                      <el-radio-button :label="2">是</el-radio-button>
                      <el-radio-button :label="1">否</el-radio-button>
                    </el-radio-group>
                  </el-col>
                </el-form-item>
              </el-form>
            </div>
          </div>

          <div class="seek">
            <el-select
              filterable
              :style="unit"
              v-model="keytype"
              placeholder="下拉选择"
            >
              <el-option
                v-for="item in sousuo"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              >
              </el-option>
            </el-select>
            <input
              class="sekint"
              type="text"
              @keyup.enter="search"
              v-model="keyword"
              placeholder="输入关键字"
            />
            <el-button
              class="chaxun"
              :disabled="isDisabled"
              type="primary"
              @click="search"
              >{{ buttonLabel }}</el-button
            >


            <div class="export" @click="export1" v-if="canExport">
              <p class="export-p">导出</p>
            </div>
            <div class="export" @click="renew">
              <p class="export-p">重置</p>
            </div>
          </div>
        </div>
      </div>
      <!-- <alter :ister="ter" @datas="add"></alter> -->
      <!-- <alter :ister="ter" ></alter> -->
      <div class="main">
        <classify :isAdd="newdata" :isrefresh="refreshs"></classify>
      </div>
    </div>
  </div>
</template>
<script>
import alter from "../components/alter.vue";
import * as api from "../api/order";
import * as api1 from "../api/index";

import classify from "../components/classify.vue";
import Score from "../components/score.vue";
const cityOptions = [
  {
    id: 1,
    name: "非事故拖车",
  },
  {
    id: 2,
    name: "事故拖车",
  },
  {
    id: 3,
    name: "搭电",
  },
  {
    id: 4,
    name: "换胎",
  },
  {
    id: 5,
    name: "送水",
  },
  {
    id: 6,
    name: "送油",
  },
  {
    id: 7,
    name: "充气",
  },
  {
    id: 8,
    name: "快修",
  },
  {
    id: 9,
    name: "开锁",
  },
  {
    id: 10,
    name: "吊车",
  },
  {
    id: 11,
    name: "困境",
  },
  {
    id: 12,
    name: "地库",
  },
  {
    id: 13,
    name: "架小轮",
  },
];
export default {
  components: { classify, alter, Score },
  data() {
    return {
      ter: 0,
      newarr: [],
      newdata: {},
      refreshs: 0,
      keytype: "",
      keyword: "",
      group: [],
      cities: cityOptions,
      heZuoVal: "",
      statusVal: "",
      valueTime1: "",
      valueTime2: "",
      valueTime3: "",
      typedanclorl: 0,
      shoufei: 0,
      tech: "",
      techlist: [],
      kefu: "",
      diaodu: "",
      diaodulist: [],
      kefulist: [],
      saleman: "",
      saleslist: [],
      shenHeVal: "",
      touSuVal: "",
      huiFangVal: "",
      heZuoOptions: [],
      statusOptions: [],
      dinglei: "",
      typeVal: "",
      sousuo: [
        {
          value: "1",
          label: "订单号",
        },
        {
          value: "2",
          label: "车主信息",
        },
        {
          value: "3",
          label: "技师信息",
        },
        {
          value: "4",
          label: "任务点",
        },
        {
          value: "5",
          label: "目的地",
        },
        {
          value: "6",
          label: "合作商",
        },
        {
          value: "7",
          label: "案件号",
        },
      ],
      isDisabled: false,
      buttonLabel: "查询",
      timer: null,
      countdown: 5,
      value1: "",
      value2: "",
      companyType: "",
      companyTypelist: [],
      canExport: false,
      sourcevalue: '0',
      sourceList: [
        {
          value: "0",
          label: "订单来源",
        },
        {
          value: "1",
          label: "平台流转",
        },
        {
          value: "2",
          label: "app",
        },
        {
          value: "3",
          label: "小程序",
        },
        {
          value: "4",
          label: "接口",
        },
        {
          value: "5",
          label: "客服",
        },
        {
          value: "6",
          label: "合作商",
        },
      ],
    };
  },
  async created() {
    let res = await api.getOrderListOp();
    //console.log(res);
    this.heZuoOptions = res.data.splist;
    this.statusOptions = res.data.orderstatus;

    let res1 = await api1.gettechlist();
    this.techlist = res1.data;
    this.canExport = res1.orderExport;

    let res2 = await api1.getsaleslista();
    this.saleslist = res2.data;

    let res3 = await api1.getkefulist();
    this.kefulist = res3.data;
    this.companyTypelist = res3.data2;

    let res4 = await api1.getdiaodulist();
    this.diaodulist = res4.data;

    console.log("数据数据", res);
    console.log("数据数据", res1);
    console.log("数据数据", res2);
    console.log("数据数据", res3);
    console.log("数据数据", res4);

    let { cid } = this.$route.query;
    console.log("合作商过来的路由传参：", cid);
    if (cid != undefined && cid != "") {
      this.heZuoVal = cid;
      this.search();
    }
  },
  methods: {
    sourcechange() {
      console.log("选中", this.sourcevalue);
    },
    fill() {
      this.ter++;
    },
    search() {
      let data = {
        page: 1,
        perpage: 8,
        createTimeBegin:
          this.valueTime1 != "" && this.valueTime1 != null
            ? this.valueTime1[0]
            : "",
        createTimeEnd:
          this.valueTime1 != "" && this.valueTime1 != null
            ? this.valueTime1[1]
            : "",
        srcCid: this.heZuoVal,
        rescueStatus: this.statusVal,
        chargeType: this.shoufei,
        finishTimeBegin:
          this.valueTime2 != "" && this.valueTime2 != null
            ? this.valueTime2[0]
            : "",
        finishTimeEnd:
          this.valueTime2 != "" && this.valueTime2 != null
            ? this.valueTime2[1]
            : "",
        reviewStatus: this.shenHeVal,
        returnStatus: this.huiFangVal,
        complainStatus: this.touSuVal,
        rescueType: this.group,
        tech: this.tech,
        saleman: this.saleman,
        kefu: this.kefu,
        diaodu: this.diaodu,
        fromtype: this.sourcevalue,
        rescueStyle: this.dinglei,
        keytype: this.keytype,
        keyword: this.keyword,
        apTimeBegin:
          this.valueTime3 != "" && this.valueTime3 != null
            ? this.valueTime3[0]
            : "",
        apTimeEnd:
          this.valueTime3 != "" && this.valueTime3 != null
            ? this.valueTime3[1]
            : "",
        rescueTimeBegin: this.value1,
        rescueTimeEnd: this.value2,
        companyType: this.companyType,
      };
      this.newdata = data;

      this.isDisabled = true;
      this.updatebtn();

      this.timer = setInterval(() => {
        if (this.countdown > 1) {
          this.countdown--;
          this.updatebtn();
        } else {
          clearInterval(this.timer);
          this.resetbtn();
        }
      }, 1000);
      console.log("点击查询", this.newdata);
    },
    shuaxin() {
      let data = {
        page: 1,
        perpage: 8,
        createTimeBegin:
          this.valueTime1 != "" && this.valueTime1 != null
            ? this.valueTime1[0]
            : "",
        createTimeEnd:
          this.valueTime1 != "" && this.valueTime1 != null
            ? this.valueTime1[1]
            : "",
        srcCid: this.heZuoVal,
        rescueStatus: this.statusVal,
        chargeType: this.shoufei,
        finishTimeBegin:
          this.valueTime2 != "" && this.valueTime2 != null
            ? this.valueTime2[0]
            : "",
        finishTimeEnd:
          this.valueTime2 != "" && this.valueTime2 != null
            ? this.valueTime2[1]
            : "",
        reviewStatus: this.shenHeVal,
        returnStatus: this.huiFangVal,
        complainStatus: this.touSuVal,
        rescueType: this.group,
        tech: this.tech,
        saleman: this.saleman,
        kefu: this.kefu,
        diaodu: this.diaodu,
        fromtype: this.sourcevalue,
        rescueStyle: this.dinglei,
        keytype: this.keytype,
        keyword: this.keyword,
        apTimeBegin:
          this.valueTime3 != "" && this.valueTime3 != null
            ? this.valueTime3[0]
            : "",
        apTimeEnd:
          this.valueTime3 != "" && this.valueTime3 != null
            ? this.valueTime3[1]
            : "",
        rescueTimeBegin: this.value1,
        rescueTimeEnd: this.value2,
        companyType: this.companyType,
      };
      this.newdata = data;
      this.refreshs++;
      console.log(this.refreshs);
    },
    updatebtn() {
      this.buttonLabel = `${this.countdown}`;
    },
    resetbtn() {
          this.isDisabled = false;
          this.buttonLabel = "查询";
          this.countdown = 3;
    },
    typedan(e) {
      console.log("点击了-", e);
      this.typedanclorl = e;
      this.dinglei = e;
    },
    shoutype(e) {
      this.shoufei = e;
    },
    export1() {
      let ct = -1;
      let at = -1;
      let rt = -1;
      let nowtime = Date.parse(new Date());

      let data = {
        page: 1,
        perpage: 8,
        createTimeBegin:
          this.valueTime1 != "" && this.valueTime1 != null
            ? this.valueTime1[0]
            : "",
        createTimeEnd:
          this.valueTime1 != "" && this.valueTime1 != null
            ? this.valueTime1[1]
            : "",
        srcCid: this.heZuoVal,
        rescueStatus: this.statusVal,
        chargeType: this.shoufei,
        finishTimeBegin:
          this.valueTime2 != "" && this.valueTime2 != null
            ? this.valueTime2[0]
            : "",
        finishTimeEnd:
          this.valueTime2 != "" && this.valueTime2 != null
            ? this.valueTime2[1]
            : "",
        reviewStatus: this.shenHeVal,
        returnStatus: this.huiFangVal,
        complainStatus: this.touSuVal,
        rescueType: this.group,
        tech: this.tech,
        saleman: this.saleman,
        kefu: this.kefu,
        diaodu: this.diaodu,
        fromtype: this.sourcevalue,
        rescueStyle: this.dinglei,
        keytype: this.keytype,
        keyword: this.keyword,
        apTimeBegin:
          this.valueTime3 != "" && this.valueTime3 != null
            ? this.valueTime3[0]
            : "",
        apTimeEnd:
          this.valueTime3 != "" && this.valueTime3 != null
            ? this.valueTime3[1]
            : "",
        rescueTimeBegin:
          this.value1 != "" && this.value1 != null ? this.value1 : "",
        rescueTimeEnd:
          this.value2 != "" && this.value2 != null ? this.value2 : "",
        companyType: this.companyType,
      };
      this.newdata = data;

      if (
        Object.keys(this.newdata).length == 0 ||
        (this.newdata.createTimeBegin == "" &&
          this.newdata.createTimeEnd == "" &&
          this.newdata.finishTimeBegin == "" &&
          this.newdata.finishTimeEnd == "" &&
          this.newdata.rescueTimeBegin == "" &&
          this.newdata.rescueTimeEnd == "")
      ) {
        this.$message.warning("请选择具体的导出日期");
        return false;
      }

      if (
        this.newdata.createTimeBegin != "" &&
        this.newdata.createTimeEnd != ""
      ) {
        ct =
          (Date.parse(this.newdata.createTimeEnd) -
            Date.parse(this.newdata.createTimeBegin)) /
          86400000;
      }

      if (
        this.newdata.finishTimeBegin != "" &&
        this.newdata.finishTimeEnd != ""
      ) {
        at =
          (Date.parse(this.newdata.finishTimeEnd) -
            Date.parse(this.newdata.finishTimeBegin)) /
          86400000;
      }

      if (
        this.newdata.rescueTimeBegin == "" &&
        this.newdata.rescueTimeEnd != "" &&
        ct < 0 &&
        at < 0
      ) {
        this.$message.warning("救援时间导出不能超出1个月，请重新选择");
        return false;
      }

      if (
        this.newdata.rescueTimeBegin != "" &&
        this.newdata.rescueTimeEnd == "" &&
        ct < 0 &&
        at < 0 &&
        (nowtime - Date.parse(this.newdata.rescueTimeBegin)) / 86400000 > 32
      ) {
        this.$message.warning("救援时间导出不能超出1个月，请重新选择");
        return false;
      }

      console.log(Date.parse(this.newdata.rescueTimeBegin));

      if (
        this.newdata.rescueTimeBegin != "" &&
        this.newdata.rescueTimeEnd != ""
      ) {
        rt =
          (Date.parse(this.newdata.rescueTimeEnd) -
            Date.parse(this.newdata.rescueTimeBegin)) /
          86400000;
      }

      if (ct > 31) {
        this.$message.warning("创建时间导出不能超出1个月，请重新选择");
        return false;
      }

      if (at > 31) {
        this.$message.warning("完成时间导出不能超出1个月，请重新选择");
        return false;
      }

      if (rt > 31) {
        this.$message.warning("救援时间导出不能超出1个月，请重新选择");
        return false;
      }

      //console.log(rt);

      //return false;

      this.$msgbox({
        title: "消息",
        message: "是否确定导出",
        showCancelButton: true,
        closeOnClickModal: false,
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            instance.confirmButtonLoading = true;
            instance.confirmButtonText = "导出中...";
            setTimeout(async () => {
              let res = await api.orderListExport(this.newdata);
              console.log("打印结果", res);
              done();
              if (res.status == 200) {
                instance.confirmButtonLoading = false;
              }
            });
          } else {
            done();
          }
        },
      })
        .then(() => {
          this.$message.success("导出成功请保存");
        })
        .catch(() => {
          this.$message.warning("取消导出");
        });
    },
    renew() {
      this.valueTime1 = "";
      this.heZuoVal = "";
      this.statusVal = "";
      this.shoufei = "";
      this.valueTime2 = "";
      this.shenHeVal = "";
      this.huiFangVal = "";
      this.touSuVal = "";
      this.group = [];
      this.tech = "";
      this.saleman = "";
      this.kefu = "";
      this.diaodu = "";
      this.sourcevalue = "0";
      this.dinglei = "";
      this.keytype = "";
      this.keyword = "";
      this.valueTime3 = "";
      this.value1 = "";
      this.value2 = "";
      this.companyType = "";
      this.newdata = {
        page: 1,
        perpage: 8,
      };
    },
  },
  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  },
  computed: {
    getismain() {
      return this.$store.state.ismain;
    },
  },
};
</script>

<style lang="less" scoped>
.box {
  box-sizing: border-box;
  width: 1730px;
  height: 840px;
  margin: 0;
  margin-left: 193px;
  margin-top: 0px;
  padding: 30px;
  background-color: #f8fafc;
  //position: relative;
}
.nav-box {
  width: 1670px;
  height: 260px;
  background-color: #fff;
  overflow: hidden;
  padding: 15px 30px;
  box-sizing: border-box;
}
.left {
  float: left;
  width: 300px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #dadfe6;
  opacity: 1;
  // margin-left: 30px;
  margin-top: 40px;
  margin-right: 850px;
  border-radius: 2px;
  position: relative;
  padding-left: 10px;
}
span {
  float: left;
  // width: 50px;
  height: 19px;
  font-size: 14px;
  margin-top: 20px;
  // margin-left: 10px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 0px;
  // margin-right: 16px;
  color: #2a3346;
  opacity: 1;
}
.jian {
  float: left;
  transform: rotate(90deg);
  margin-right: 17px;
  margin-top: 10px;
}
.line {
  float: left;
  width: 0px;
  height: 14px;
  margin-top: 13px;
  margin-right: 14px;
  margin-left: 14px;
  border: 1px solid #dadfe6;
  opacity: 1;
}
.screen {
  float: left;
  width: 130px;
  height: 50px;
  margin-top: 30px;
  background: #2c68ff;
  // border: 1px solid #2c68ff;
  opacity: 1;
  border-radius: 2px;
  cursor: pointer;
}
.flush {
  float: left;
  width: 130px;
  height: 50px;
  border: 1px solid #2c68ff;
  opacity: 1;
  border-radius: 2px;
  cursor: pointer;
  box-sizing: border-box;
}
.screen-img {
  float: left;
  width: 24px;
  height: 24px;
  margin-right: 12px;
  margin-left: 27px;
  margin-top: 14px;
}
.screen-p {
  float: left;
  margin-top: 27px;
  // width: 32px;
  height: 22px;
  font-size: 16px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 0px;
  color: #ffffff;
  opacity: 1;
}
.export-img,
.flush-img {
  float: left;
  width: 24px;
  height: 24px;
  margin-right: 16px;
  margin-left: 27px;
  margin-top: 12px;
  box-sizing: border-box;
}
.export-p,
.flush-p {
  float: left;
  // width: 32px;
  height: 22px;
  font-size: 16px;
  margin-top: 25px;
  font-family: PingFang SC;
  font-weight: 500;
  line-height: 0px;
  color: #2c68ff;
  opacity: 1;
}
.dingkuang {
  float: left;
  width: 100%;
  height: 32px;
  font-size: 14px;
  // margin-top: 10px;
  border: none;
  outline: none;
  font-family: Microsoft YaHei;
  font-weight: 400;
  line-height: 32px;
  color: #9ea3b9;
  opacity: 1;
}
.jing {
  float: left;
  width: 32px;
  height: 32px;
  background: #f7f9fb;
  border: 1px solid #dadfe6;
  opacity: 1;
  border-radius: 2px;
  position: absolute;
  top: -1px;
  right: -1px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.icon {
  width: 14px;
  height: 14px;
  border-radius: 50%;
  opacity: 1;
}
.icon-icon1:before {
  float: left;
  margin-top: 12px;
  margin-right: 10px;
}

.main {
  width: 1670px;
  height: 530px;
  padding-left: 30px;

  box-sizing: border-box;
  background-color: #fff;
}

.fuwuxiangmu,
.xuanze,
.xuanze2 {
  width: 1610px;
  // height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
}
.xuanbox {
  display: flex;
  height: 100%;
}
// .xuanze2{
//   margin-top: 15px;
// }
.fuwuxiangmu {
  border-bottom: 1px solid #d8d8d8;
  margin-bottom: 15px;
  padding-bottom: 10px;
  box-sizing: border-box;
}
.fuwuxiangmu /deep/ .el-col-12 {
  max-width: 100% !important;
  height: 100%;
  box-sizing: border-box;
}
.fuwuxiangmu /deep/ .el-checkbox-button {
  margin-right: 10px;
}
.fuwuxiangmu /deep/ .el-checkbox-button__inner {
  border: 1px solid #dcdfe6;
}

.xuanbox /deep/ .el-select .el-input__inner {
  width: 140px;
  height: 40px;
}
.xuanbox /deep/ .el-range-editor.el-input__inner {
  width: 230px;
  height: 40px;
}
.xuandan {
  height: 100%;
  display: flex;
  align-items: center;
}
i {
  font-style: normal;
}
.quanbustyle,
.jishistyle,
.yuyuestyle,
.shunlustyle {
  // display: inline-block;
  // width: 78px;
  height: 100%;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  opacity: 1;
  border-radius: 4px;
  vertical-align: top;
  padding: 12px 20px;
  cursor: pointer;
  color: #666;
  margin-right: 10px;
  background-color: #ededed;
}
.quanbustyle2,
.jishistyle2,
.yuyuestyle2,
.shunlustyle2 {
  // display: inline-block;
  // width: 78px;
  height: 100%;
  font-size: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  opacity: 1;
  border-radius: 4px;
  vertical-align: top;
  padding: 12px 20px;
  cursor: pointer;
  color: #fff;
  margin-right: 10px;
  background-color: #2c68ff;
}

.newxuan {
  border: 1px solid #e2e2e5;
  height: 40px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.tiao {
  display: inline-block;
  width: 0px;
  height: 14px;
  // margin-right: 10px;
  box-sizing: border-box;
  border: 1px solid #dadfe6;
  opacity: 1;
}
.zhang,
.shoufeiz {
  font-size: 14px;
  margin: 0 10px;
  cursor: pointer;
}
.zhang {
  color: #9e9e9e;
}
.shoufeiz {
  color: #2c68ff;
}
.xuan1 {
  margin-left: 10px;
}
.xuan1 /deep/ .el-select .el-input__inner {
  width: 150px;
  height: 40px;
}
.danxuanbox {
  width: 1610px;
  box-sizing: border-box;
  display: flex;
  margin-top: 10px;
  align-items: center;
  justify-content: space-between;
}
.danxuan {
  margin-right: 50px;
  height: 40px;
  display: flex;
  align-items: center;
  // padding-top: 10px;
  box-sizing: border-box;
  border: 1px solid #e1e5eb;
  padding-right: 10px;
  box-sizing: border-box;
}
.danxuan /deep/ .el-radio-button__inner {
  border: 0;
  border-radius: 2px;
  margin-left: 10px;
}
.danxuan
  /deep/
  .el-radio-button__original-radio:checked
  + .el-radio-button__inner {
  background-color: #2c68ff;
}
.danxuanbox /deep/ .el-radio-group {
  display: flex;
  flex-wrap: inherit;
}
.danxuanleft {
  display: flex;
}
.danxuan /deep/ .el-form-item--default {
  margin: 0;
}
.seek {
  float: left;
  opacity: 1;
  box-sizing: border-box;
  border-radius: 2px;
  position: relative;
  display: flex;
}
.sekint {
  float: left;
  width: 156px;
  height: 40px;
  padding: 15px;
  outline: none;
  border: none;
  box-sizing: border-box;
  border: 1px solid #dadfe6;
  border-left: 0;
}
.seek /deep/ .el-select .el-input__inner {
  width: 110px;
  height: 40px;
}
.chaxun {
  width: 80px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  cursor: pointer;
  border-radius: 0;
}
.export {
  display: flex;
  width: 80px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border: 1px solid #727e96;
  box-sizing: border-box;
  margin-left: 20px;
  cursor: pointer;
}
.fuwuleft {
  display: flex;
  box-sizing: border-box;
  align-items: center;
}
.fuwuleft /deep/ .el-checkbox-button--large .el-checkbox-button__inner {
  box-sizing: border-box;
}
.xuanbox /deep/ .el-form-item--default .el-form-item__label {
  display: flex;
  align-items: center;
}
.xuanbox /deep/ .el-input--prefix .el-input__inner {
  margin-left: 10px;
  height: 40px;
}
.xuanbox /deep/ .el-input .el-input__icon {
  margin-left: 10px;
}
.seek /deep/ .el-select .el-input__inner {
  border-radius: 0;
}
.danxuan /deep/ .el-form-item--default .el-form-item__label {
  font-size: 14px;
  font-weight: bold;
  background-color: #e2e6ec;
  padding-left: 10px;
  height: 40px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  position: relative;
}
.danxuan /deep/ .el-form-item--default .el-form-item__label::before {
  content: "";
  display: inline-block;
  width: 0;
  height: 0;
  position: absolute;
  right: -18px;
  top: 12px;
  border: 9px solid #e2e6ec;
  border-top-color: transparent;
  // border-left-color: transparent;
  border-right-color: transparent;
  border-bottom-color: transparent;
}
.xuanzeleft,
.xuanzeright {
  display: flex;
}
.xuanzeright .xuanbox {
  margin-left: 10px;
}
.xuanzeleft .xuanbox {
  margin-right: 10px;
}


</style>